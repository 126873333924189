import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { IconBulb, IconChart, IconDocument, IconFlame } from "../assets"

import { Text, Box, SectionTitle, Row } from "./atoms"

export const AboutCompany = () => {
  const { t } = useTranslation()

  return (
    <Box pt={5} id="aboutCompany" px={3} pb={5}>
      <SectionTitle mb={4}>{t("aboutCompany.title")}</SectionTitle>
      <Text fontSize={1} fontWeight="medium" textAlign="center" mx={2}>{t("aboutCompany.description")}</Text>
      <Row flexWrap="wrap" flexGrow={1} mt={5} alignItems="flex-start">
        {awardsData.map(AboutAwardItem)}
      </Row>
    </Box>
  )
}

const AboutAwardItem = ({ iconBackgroundColor, text, iconName }) => {
  const { t } = useTranslation()
  const IconComponent = iconName
  return (
    <Box width={[1 / 2, 1 / 2, 1 / 4]} alignItems="center" mb={3}>
      <Box
        width={80}
        height={80}
        backgroundColor={iconBackgroundColor}
        borderRadius={40}
        mb={1}
        alignItems="center"
        justifyContent="center"
      >
        <IconComponent
          width={50}
          height={50}
        />
      </Box>
      <Text textAlign="center" mx={2}>{t(text)}</Text>
    </Box>
  )
}

// <--- Data Keys --->
const awardsData = [
  {
    iconBackgroundColor: "secondary",
    text: "aboutCompany.award.text.1",
    iconName: IconChart
  },
  {
    iconBackgroundColor: "primary",
    text: "aboutCompany.award.text.2",
    iconName: IconBulb
  },
  {
    iconBackgroundColor: "secondaryLight",
    text: "aboutCompany.award.text.5",
    iconName: IconDocument
  },
  {
    iconBackgroundColor: "primaryLight",
    text: "aboutCompany.award.text.3",
    iconName: IconFlame
  }]
