import React from "react"
import styled from "styled-components"
import css from '@styled-system/css'
import { space, layout, color, flexbox, typography, fontSize, fontWeight, fontFamily, fontStyle } from "styled-system"

export const Text = styled('p')(
  css({
    display: 'flex',
    color: 'primary',
    fontWeight: "normal",
    fontSize: 2,
    whiteSpace: "pre-line",
    margin: 0
  }),
  space,
  layout,
  color,
  flexbox,
  typography,
  fontSize,
  fontWeight,
  fontFamily,
  fontStyle
)

export const SectionTitle = ({ children, ...props }) => (
  <Text fontSize={3} alignSelf="center" fontWeight="medium" {...props}>
    {children}
  </Text>
)
