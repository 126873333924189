import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { Row, Text, Box, MapView, Icon, iconTypes } from "./atoms"

export const Contact = () => {
  const { t } = useTranslation()

  return (
    <Row pb={5} pt={3} flexWrap="wrap" id="contact" px={3}>
      <Box width={[1, 1/3]} mb={2}>
        <Text fontSize={4} mb={3}>{t("contact.company.name")}</Text>
        <Text fontSize={1}>{t("contact.company.address")}</Text>
        <Row justifyContent="flex-start" mt={5}>
          <ActionItem icon={iconTypes.PHONE} link={`tel:+421245692591`}/>
          <ActionItem icon={iconTypes.MAIL} link={`mailto:m-chemicals@m-chemicals.sk`}/>
        </Row>
      </Box>
      <MapView coordinates={[48.14114, 17.09127]} zoom={16} markerText={t('contact.company.name')}/>
    </Row>
  )
}

const ActionItem = ({icon, link}) => {
  return <Icon icon={icon} link={link} />
}
