import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { ThemeProvider } from "styled-components"
import theme from "../utils/theme"

import {
  Layout,
  Header,
  SEO,
  Motto,
  Products,
  Testimonials,
  AboutCompany,
  OurTeam,
  Contact,
  Connections
} from "../components"

const Home = () => {
  const { t } = useTranslation()

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title={t("page.home")}/>
        <Header/>
        <Motto/>
        <Products/>
        <Testimonials/>
        <AboutCompany/>
        <Connections/>
        <OurTeam/>
        <Contact/>
      </Layout>
    </ThemeProvider>
  )
}

export default Home
