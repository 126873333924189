import React from "react"
import { IllustrationLaboratory } from '../assets'
import { useTranslation } from "gatsby-plugin-react-i18next"

import { Button, Text, Box, Row } from "./atoms"

export const Motto = () => {
  const { t } = useTranslation()

  return (
    <Row flexWrap="wrap" mx={3}>
      <Box width={[1,1/2,2/5]} >
        <Text fontSize={[4,5]} mb={3}>{t("motto.text.0")}</Text>
        <Button title={t("motto.button.title")} originalPath={"/#contact"} variant="secondary" />
      </Box>
      <IllustrationLaboratory width={500}/>
    </Row>

  )
}
