import React from "react"
import { Box } from './atoms'

export const Layout = ({ children }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <Box style={styles.defaultStyle}>
      {children}
    </Box>
  )
}

const styles = {
  defaultStyle: {
    margin: `0 auto`,
    maxWidth: 1100,
    padding: `0 0`,
    fontFamily: "poppins",
    fontWeight: 'medium'
  },
}
