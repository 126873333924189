import { Text } from "./text"
import { Box } from "./box"
import React from "react"
// Icon Import
import { FaFax } from "@react-icons/all-files/fa/FaFax"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt"

export const iconTypes = {
  FAX: FaFax,
  MAIL: FaEnvelope,
  PHONE: FaPhoneAlt
}
// <--- Component --->
export const Icon = ({ icon, link = '', ...props }) => {
  const IconComponent = icon

  return (
    <a href={link}>
      <Box
        width={40}
        height={40}
        borderRadius={20}
        backgroundColor="secondary"
        mr={4}
        flexShrink={0}
        justifyContent="center"
        alignItems="center"
        {...props}
      >
        <Text color="white"><IconComponent/></Text>
      </Box>
    </a>
  )
}
