import React from "react"
import { Box } from "./box"
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"

export const MapView = ({ markerText = "", coordinates, zoom, ...props }) => {
  if (typeof window !== "undefined") {
    return (
      <Box flexGrow={1} {...props}>
        <MapContainer center={coordinates} zoom={zoom} style={{ width: "100%", height: 200, borderRadius: 8, minWidth: 100 }}>
          <TileLayer
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          />
          <Marker position={coordinates}>
            <Popup>{markerText}</Popup>
          </Marker>
        </MapContainer>
      </Box>

    )
  }
  return null
}
