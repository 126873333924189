import { useTranslation } from "gatsby-plugin-react-i18next"
import { Box, Text, Row, SectionTitle } from "./atoms"
import { IconChemicals, IconAtom, IconOneChemical } from "../assets"
import React from "react"

const ProductCategories = {
  "FIRST": 0,
  "SECOND": 1,
  "THIRD": 2
}
export const Products = () => {
  const { t } = useTranslation()

  return (
    <Box pt={5} id="products" px={3}>
      <SectionTitle mb={4}>{t("products.title")}</SectionTitle>
      <ProductsRow categoryType={ProductCategories.FIRST}/>
      <ProductsRow categoryType={ProductCategories.SECOND}/>
      <ProductsRow categoryType={ProductCategories.THIRD}/>
    </Box>
  )
}

const ProductsRow = ({ categoryType }) => {
  const { t } = useTranslation()
  let productItems, IconComponent
  switch (categoryType) {
    case ProductCategories.FIRST:
      productItems = firstCategoryItems
      IconComponent = IconChemicals
      break
    case ProductCategories.SECOND:
      productItems = secondCategoryItems
      IconComponent = IconOneChemical
      break
    case ProductCategories.THIRD:
      productItems = thirdCategoryItems
      IconComponent = IconAtom
      break
    default:
      break
  }

  return (
    <Row mb={4}>
      <Box
        width={80}
        height={80}
        backgroundColor="secondary"
        mx={4}
        flexShrink={0}
        borderRadius={40}
        alignItems="center"
        justifyContent="center"
      >
        <IconComponent
          width={50}
          height={50}
        />
      </Box>
      <Row flexWrap="wrap" flexGrow={1}>
        {productItems.map((item, index) => (
          <Box width={[1, 1 / 2, 1 / 3]} mb={2}>
            <Text key={index}>{`* ${t(item)}`}</Text>
          </Box>))}
      </Row>
    </Row>
  )
}

// <--- Data Keys --->
const firstCategoryItems = [
  "product.item.category.0.item.0",
  "product.item.category.0.item.1",
  "product.item.category.0.item.2",
  "product.item.category.0.item.3",
  "product.item.category.0.item.4",
  "product.item.category.0.item.5",
  "product.item.category.0.item.6",
  "product.item.category.0.item.7",
  "product.item.category.0.item.8",
  "product.item.category.0.item.9",
  "product.item.category.0.item.10",
  "product.item.category.0.item.11"
]

const secondCategoryItems = [
  "product.item.category.1.item.0",
  "product.item.category.1.item.1",
  "product.item.category.1.item.2",
  "product.item.category.1.item.3",
  "product.item.category.1.item.4",
  "product.item.category.1.item.5",
  "product.item.category.1.item.6",
  "product.item.category.1.item.7",
  "product.item.category.1.item.8"
]

const thirdCategoryItems = [
  "product.item.category.2.item.0",
  "product.item.category.2.item.1",
  "product.item.category.2.item.2",
  "product.item.category.2.item.3",
  "product.item.category.2.item.4",
  "product.item.category.2.item.5",
  "product.item.category.2.item.6",
  "product.item.category.2.item.7",
  "product.item.category.2.item.8",
  "product.item.category.2.item.9",
  "product.item.category.2.item.10",
  "product.item.category.2.item.11",
  "product.item.category.2.item.12",
  "product.item.category.2.item.13"
]
