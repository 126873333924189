import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import { Text, Box, SectionTitle, Row, Icon, iconTypes } from "./atoms"

export const OurTeam = () => {
  const { t } = useTranslation()

  return (
    <Box pt={5} px={3}>
      <SectionTitle mb={4}>{t("ourTeam.title")}</SectionTitle>
      <Row flexWrap="wrap" flexGrow={1} mt={3} justifyContent="center">
        {headTeamData.map(TeamCard)}
      </Row>
      <Box mt={3}>
        <Text fontSize={3} fontWeight="normal" alignSelf="center" mb={2}>{t("ourTeam.department.sales")}</Text>
        <Row flexWrap="wrap" flexGrow={1} justifyContent="center">
          {salesTeamData.map(TeamCard)}
        </Row>
      </Box>
      <Box mt={3}>
        <Text fontSize={3} fontWeight="normal" alignSelf="center" mb={2}>{t("ourTeam.department.economic")}</Text>
        <Row flexWrap="wrap" flexGrow={1} justifyContent="center">
          {economicsTeamData.map(TeamCard)}
        </Row>
      </Box>
    </Box>
  )
}

const TeamCard = ({ name, position, phoneNumber, email }) => {
  const { t } = useTranslation()

  return (
    <Row alignItems="flex-start" mb={3} borderColor="primary" borderWidth={1} borderRadius={8} borderStyle="solid" p={3}
         mx={3}>
      <Box>
        <Text textAlign="center" fontWeight="bold" color="black" fontSize={1}>{t(name)}</Text>
        <Text textAlign="center" color="black" fontSize={1}>{t(position)}</Text>
        <Row mt={3}>
          <ActionItem icon={iconTypes.PHONE} link={`tel:${phoneNumber}`}/>
          <ActionItem icon={iconTypes.MAIL} link={`mailto:${email}`}/>
        </Row>
      </Box>
    </Row>
  )
}

const ActionItem = ({icon, link}) => {
  return <Icon icon={icon} link={link} backgroundColor="primary"/>
}

// <--- Data Keys --->
const headTeamData = [
  {
    name: "Ing.Pavel Pleva",
    position: "ourTeam.member.position.0",
    phoneNumber: "+421905968800",
    email: "pavol.pleva@m-chemicals.sk"
  }
]

const salesTeamData = [
  {
    name: "Hedviga Plevová",
    position: "ourTeam.member.position.1",
    phoneNumber: "+421905474492",
    email: "hedviga.plevova@m-chemicals.sk"
  },
  {
    name: "Zuzana Vašková",
    position: "ourTeam.member.position.2",
    phoneNumber: "+421918796777",
    email: "zuzana.vaskova@m-chemicals.sk"
  },
  {
    name: "Linda Tóthová",
    position: "ourTeam.member.position.3",
    phoneNumber: "+421245692591",
    email: "linda.tothova@m-chemicals.sk"
  }
]

const economicsTeamData = [
  {
    name: "Dana Vallová",
    position: "ourTeam.member.position.4",
    phoneNumber: "+421245692591",
    email: "dana.vallova@m-chemicals.sk"
  }
]
