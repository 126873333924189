import styled from "styled-components"
import { space, layout, color, flexbox, border, display } from "styled-system"

export const Row = styled('div')(
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: "center"
  },
  space,
  layout,
  color,
  flexbox,
  border,
  display
)
