import styled from "styled-components"
import { space, layout, color, flexbox, border, display } from "styled-system"

export const Box = styled('div')(
  {
    display: 'flex',
    flexDirection: 'column',
  },
  space,
  layout,
  color,
  flexbox,
  border,
  display
)
