import React from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-react-i18next"
import { variant } from "styled-system"

import { Row } from "./row"

const StyledButton = styled(Row)`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  text-decoration: none;
  text-align: center;
  :hover {
    text-decoration: none;
  }
  ${variant({
  variants: {
    primary: {
      color: "white",
      bg: "primary",
      px: 3,
      py: 2,
      borderRadius: 999
    },
    secondary: {
      color: "white",
      bg: "secondary",
      px: 3,
      py: 2,
      borderRadius: 999,
      fontSize: 3,
      fontWeight: "medium"
    },
    menu: {
      color: "primary",
      bg: "transparent",
      p: 0,
      fontSize: 1,
      fontWeight: "normal"
    }
  }
})}
`

export const Button = ({ title, originalPath, variant, rightComponent, language, onPress, ...props }) => {
  if(onPress){
    return(
      <Row {...props}>
        <StyledButton variant={variant} onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          onPress()
        }}>
          {title}
          {rightComponent}
        </StyledButton>
      </Row>
    )
  }
  return (
    <Row {...props}>
      <StyledButton variant={variant} as={Link} to={originalPath} language={language}>
        {title}
        {rightComponent}
      </StyledButton>
    </Row>
  )
}
