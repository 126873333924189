import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const SimpleSlider = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: Math.floor(slides?.length / 2 || 0)
  }
  return (
    <Slider {...settings}>
      {slides.map(content => content)}
    </Slider>
  )
}

