export default {
  colors: {
    primary: "#0264A8",
    primaryLight: "#048AE9",
    primaryDark: "#024F86",
    secondary: "#FF9000",
    secondaryLight: "#FF9812",
    secondaryDark: "#D17600",
    white: "#FCFCFC",
    black: "#0A0A0A",
    error: "#DE4B3E"
  },
  fonts: "poppins",
  fontWeights: {
    body: 300,
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
    heading: 700
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32],
  breakpoints: ["576px", "768px", "1024px"]
}
