import React, { useState } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import {
  ComposableMap,
  Geographies,
  Geography
} from "react-simple-maps"

import { Box, Button, SectionTitle } from "./atoms"

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-50m.json"

export const mapTypes = {
  EUROPE: "europe",
  WORLD: "world"
}

export const Connections = () => {
  const { t } = useTranslation()
  const [selectedMapType, setSelectedMapType] = useState(mapTypes.EUROPE)

  const handleButtonPress = () => {
    console.log("s")
    setSelectedMapType(selectedMapType === mapTypes.EUROPE ? mapTypes.WORLD : mapTypes.EUROPE)
  }

  let title, buttonTitle, mapConfig, projection
  switch (selectedMapType) {
    case mapTypes.EUROPE:
      title = t("connections.europe.title")
      buttonTitle = t("connections.europe.button.title")
      mapConfig = {
        rotate: [-20.0, -52.0, 0],
        scale: 900
      }
      projection = "geoAzimuthalEqualArea"
      break
    case mapTypes.WORLD:
      title = t("connections.world.title")
      buttonTitle = t("connections.world.button.title")
      projection = "geoNaturalEarth1"
      break
    default:
      break
  }

  return (
    <Box py={4} px={3} id="connections" width={[9 / 10, 9 / 10, 2 / 3]} alignSelf="center" alignItems="center">
      <SectionTitle mb={4}>{title}</SectionTitle>
      <ComposableMap
        projection={projection}
        projectionConfig={mapConfig}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geo => {
              const CountryCode = geo?.properties?.ISO_A2
              const connectedCountry = ConnectionCountries.find(country => country === CountryCode)
              let fillColor = "#FFF"
              if (connectedCountry) {
                fillColor = "#FF9000"
              }

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={fillColor}
                  stroke="#024F86"
                />
              )
            })
          }
        </Geographies>
      </ComposableMap>
      <Button title={buttonTitle} onPress={handleButtonPress} variant="secondary" mt={3}/>
    </Box>
  )
}

const ConnectionCountries = [
  "SK",
  "CZ",
  "DE",
  "IT",
  "BE",
  "NL",
  "LU",
  "FR",
  "AT",
  "PL",
  "LT",
  "SI",
  "HR",
  "RS",
  "BY",
  "UA",
  "RU",
  "US",
  "HN",
  "HK",
  "KR"
]
