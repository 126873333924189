import React from "react"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"

import { IconEN, IconSK, Logo } from "../assets"
import { Row, Box, Button } from "./atoms"

const MARGIN_RIGHT = 4
export const Header = () => {
  const { t } = useTranslation()

  return (
    <Row ml={2} mr={3}>
      <Box mr={4}>
        <Logo style={styles.logo}/>
      </Box>
      <Row justifyContent="space-between">
        <Button display={['none', 'flex']} title={t("header.button.title.Products")} variant="menu" mr={MARGIN_RIGHT} originalPath="/#products"/>
        <Button display={['none', 'flex']} title={t("header.button.title.Connections")} variant="menu" mr={MARGIN_RIGHT} originalPath="/#connections"/>
        <Button display={['none', 'flex']} title={t("header.button.title.About")} variant="menu" mr={MARGIN_RIGHT} originalPath="/#aboutCompany"/>
        <Button display={['none', 'flex']} title={t("header.button.title.Contact")} variant="menu" mr={MARGIN_RIGHT} originalPath="/#contact"/>
        <HeaderLanguageButton/>
      </Row>
    </Row>
  )
}

const HeaderLanguageButton = () => {
  const { t } = useTranslation()
  const { originalPath, language } = useI18next()

  const nextLanguage = language === "sk" ? "en" : "sk"
  const title = `${t(`header.button.title.${nextLanguage}`)} `
  const Icon = nextLanguage === "sk" ? IconSK : IconEN

  return (
    <Button
      title={title}
      originalPath={originalPath}
      language={nextLanguage}
      variant="menu"
      rightComponent={<Icon style={styles.icon}/>}
    />
  )
}

const styles = {
  logo: {
    width: 100,
    height: 80
  },
  icon: {
    height: 18,
    marginLeft: 8
  }
}
