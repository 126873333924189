import { useTranslation } from "gatsby-plugin-react-i18next"
import { Box, SimpleSlider, SectionTitle, Text } from "./atoms"
import React from "react"

export const Testimonials = ({ data }) => {
  console.log(data)
  const { t } = useTranslation()
  const slides = [
    <TestimonialCard text={t("testimonials.0.text")} position={t("testimonials.0.position")}/>,
    <TestimonialCard text={t("testimonials.1.text")} position={t("testimonials.1.position")}/>,
    <TestimonialCard text={t("testimonials.2.text")} position={t("testimonials.2.position")}/>,
    <TestimonialCard text={t("testimonials.3.text")} position={t("testimonials.3.position")}/>
  ]
  return (
    <Box width={[1, 1, 2 / 3]} alignSelf="center" pt={5} px={3} pb={5}>
      <SectionTitle mb={4}>{t("testimonials.title")}</SectionTitle>
      <SimpleSlider slides={slides}/>
    </Box>
  )
}

const TestimonialCard = ({ text, position }) => {
  return (
    <Box alignItems="center">
      <Text fontSize={[2, 2, 3]} fontWeight="medium" mb={2} textAlign="center">{text}</Text>
      <Text fontSize={1} fontWeight="medium" color="secondary">{position}</Text>
    </Box>
  )
}
